<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.ACCOUNT_FEES_PROFIT_COMMISSIONS') }}
    </p>

    <section class="filter-part sm-content">
      <div class="form-item">
        <label>{{ $t('MANAGE_SHANIV.CATEGORY') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('SUPPLIER_PAYMENT.CARD_TYPE') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('REPORT_SALES.SUPPLIER_ID') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('GIFTCARD_PAYMENT_RESULT.PRODUCT') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>

    <section class="sm-content m-t-10">
      <label>{{ $t('DESTINATION.TAGS') }}</label>
      <div class="tag-block">
        <img src="/assets/img/icons/search-light.svg" alt="" class="search-icon" />
        <div class="tag-close_btn total-tag-close">
          <img src="/assets/img/icons/times-light.svg" alt="" />
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('DESTINATION.TAGS') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('DESTINATION.TAGS') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="sm-content m-t-30">
      <div class="d-flex align-items-end bottom-setting">
        <div class="detail-form">
          <label>{{ $t('MANAGEMENT.CUSTOMER_TYPE') }}</label>
          <div class="m-t-5">
            <input
              type="text"
              class="input"
              :placeholder="$t('REPORT_SALES.EVERYTHING')"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-end bottom-setting m-t-30">
        <div class="detail-form m-b-15">
          <label>{{ $t('MANAGEMENT.COMMISSIONER_DISTRIBUTOR_COMMISSION') }}</label>
          <div class="m-t-5">
            <input
              type="number"
              class="input"
              value="10"
            />
          </div>
        </div>
        <button class="primary-btn m-b-15">
          {{ $t('MANAGEMENT.APPLY_PROFIT_COMMISSION_TO_MARKETERS') }}
        </button>
        <button class="primary-btn bg-red3 m-b-15">
          {{ $t('MANAGEMENT.REMOVE_PROFIT_MARGIN_ON_DISTRIBUTORS') }}
        </button>
      </div>
      <div class="d-flex align-items-end bottom-setting m-t-15">
        <div class="detail-form m-b-15">
          <label>{{ $t('MANAGEMENT.CONSUMER_COMMISSION') }}</label>
          <div class="m-t-5">
            <input
              type="number"
              class="input"
              value="2"
            />
          </div>
        </div>
        <button class="primary-btn m-b-15">
          {{ $t('MANAGEMENT.APPLY_CONSUMER_FEE_TO_RESELLERS') }}
        </button>
        <button class="primary-btn bg-red3 m-b-15">
          {{ $t('MANAGEMENT.REMOVE_CONSUMER_FEE_ON_DISTRIBUTORS') }}
        </button>
      </div>
      <div class="d-flex align-items-center timing-vat">
        <v-checkbox
          color="#0D3856"
          class="cus-checkbox"
          v-model="isCommissionLevel"
        ></v-checkbox>
        <label class="px10 color-primary cancel-label">{{
          $t('MANAGEMENT.COMMISSION_LEVEL')
        }}</label>
      </div>
      <div class="m-t-15" v-if="isCommissionLevel">
        <label class="space-nowrap d-block">{{ $t('MANAGEMENT.CONSUMER_COMMISSION_LEVEL') }}</label>
        <input
          type="text"
          class="input px10 commission-level"
          value="5"
          @keypress="isNumber($event)"
        />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'SCCommissions',
  data() {
    return {
      isCommissionLevel: false,
    }
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.filter-part {
  display: flex;
  justify-content: space-between;
  .form-item {
    width: calc(25% - 30px);
  }
}
.detail-form {
  width: 100%;
  max-width: 250px;
}
.bottom-setting {
  .primary-btn {
    width: unset;
    padding: 0 20px;
    margin-right: 30px;
    min-width: 350px;
    font-size: 16px;
  }
}
.commission-level {
  max-width: 250px;
}

@media screen and (max-width: 980px) {
  .filter-part {
    flex-wrap: wrap;
    .form-item {
      width: 100%;
    }
  }
  .bottom-setting {
    flex-wrap: wrap;
    .primary-btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .detail-form {
    max-width: unset;
  }
}

.ltr-type {
  .bottom-setting .primary-btn {
    margin-right: 0;
    margin-left: 30px;
  }
}
</style>